import axios from "axios";
import router from "@/router";
import Swal from "sweetalert2";

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
});

// Log ogni richiesta
api.interceptors.request.use((config) => {
    const token = localStorage.getItem("jwt");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

// Gestione risposte e token scaduto
api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        const isAuthError = error.response?.status === 401;
        const isRefreshCall = originalRequest.url?.includes("/auth/refresh");

        if (isAuthError && !originalRequest._retry && !isRefreshCall) {
            originalRequest._retry = true;

            try {
                const res = await api.post("/auth/refresh");

                const newToken = res.data.token;
                localStorage.setItem("jwt", newToken);

                originalRequest.headers.Authorization = `Bearer ${newToken}`;

                return api(originalRequest);
            } catch (refreshError) {

                localStorage.removeItem("jwt");

                Swal.fire({
                    icon: "warning",
                    title: "Sessione scaduta",
                    text: "Effettua nuovamente l'accesso",
                    timer: 1500,
                    showConfirmButton: false,
                }).then(() => {
                    router.push("/auth/signin-basic");
                });

                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default api;